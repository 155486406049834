import styled from "styled-components";
import {
  Button,
  ColumnFlex,
  Heading,
  LabelHeading,
  RowFlex,
  SubText,
  Text,
  TextHeading,
  TriText,
} from "./styled";

import { useConnect } from "@stacks/connect-react";
import { useAppState } from "./state";
import { APP_COLORS } from "../const/colors";

import LandingLogo from "../assets/transparentArtie.png";
import LandingLeft from "../assets/artiesLeft.png";

import { height, maxWidth } from "styled-system";

const ArtLand = () => {
  const { doOpenAuth } = useConnect();
  const initAuth = () => {
    doOpenAuth();
  };

  return (
    <ColumnFlex width="inherit" alignItems={"center"}>
      <ColumnFlex
        style={{
          backgroundColor: "white",
          height: "180px",
          width: "100%",

          paddingTop: "40px",
          position: "relative",
        }}
        alignItems="center"
      >
        <LabelHeading style={{}} textAlign={"center"}>
          We are releasing the Arties to the Community.
        </LabelHeading>
        <TriText
          style={{
            textAlign: "center",
          }}
          color={APP_COLORS.textGrey}
        >
          start block
        </TriText>
        <div
          style={{
            position: "absolute",
            bottom: "-100px",
            borderRadius: "50%",
          }}
        >
          <img
            style={{
              borderRadius: "50%",
              height: "180px",
              filter:
                "drop-shadow(6px 6px 4px rgba(0, 0, 0, 0.5)) drop-shadow(-7px 2px 4px rgba(0, 0, 0, 0.5))",
            }}
            src={LandingLogo}
            alt=""
          />
        </div>
      </ColumnFlex>
      <ColumnFlex
        justifyContent={"flex-start"}
        alignItems="center"
        style={{
          backgroundColor: APP_COLORS.lightRed,
          height: "540px",
          width: "100%",
        }}
      >
        <LabelHeading
          color="white"
          style={{
            marginTop: "120px",
          }}
          textAlign={"center"}
        >
          Wait - What’s Going On?
        </LabelHeading>
        <SubText
          style={{
            width: "80%",
            maxWidth: "750px",
          }}
          color={"white"}
          textAlign={"center"}
        >
          Open Art Source is no more.
        </SubText>
        <SubText
          style={{
            width: "80%",
            maxWidth: "750px",
          }}
          color={"white"}
          textAlign={"center"}
        >
          While Open Art Source and the Arties collection seem to be making a
          dent in the Stacks universe, it wasn’t enough to secure all the funds
          we needed to build our NFT for physical art platform.
        </SubText>
        <SubText
          style={{
            width: "80%",
            maxWidth: "750px",
          }}
          color={"white"}
          textAlign={"center"}
        >
          We could not secure the funding we needed to complete the project that
          we were building. We are shutting down the operation, and we want to
          show our appreciation to the people who followed and invested in our
          project by buying and holding an Artie all of that time. We will
          release the code we developed on Github. There will be a link in the
          Discord channel for that.
        </SubText>
        <div
          style={{
            margin: "20px",
          }}
        />
        <TextHeading color={"black"}>Refund Over</TextHeading>
      </ColumnFlex>
      <div
        style={{
          backgroundColor: APP_COLORS.red,
          height: "600px",
          width: "100%",
          maxHeight: "800px",
        }}
      >
        <RowFlex
          style={{
            position: "relative",
          }}
          justifyContent={"center"}
          height="100%"
          alignItems="center"
        >
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
            }}
          >
            <img
              style={{
                height: "450px",
              }}
              src={LandingLeft}
              alt=""
            />
          </div>
          <div
            style={{
              height: "450px",
              width: "450px",
            }}
          />
          <ColumnFlex>
            <div
              style={{
                maxWidth: "400px",
                color: "white",
                marginBottom: "50px",
              }}
            >
              <TextHeading>Wait - What’s Going On?</TextHeading>
              <TriText>
                The Arties refund is for owners who bought at the initial sale
                and still hold their Arties in the original wallet. Users will
                need to burn their Arties to get a refund. The refund will be
                for 48 STX.
              </TriText>
            </div>
            <div
              style={{
                maxWidth: "400px",
                color: "white",
              }}
            >
              <TextHeading>
                Why is the refund not for the original price of 98 STX?
              </TextHeading>
              <TriText>
                Open Art Source needs to pay its tax, legal, and other bills. We
                need the rest of the funds for that.
              </TriText>
            </div>
          </ColumnFlex>
          <ColumnFlex margin={"0 0 0 20px"}>
            <div
              style={{
                maxWidth: "400px",
                color: "white",
                marginBottom: "50px",
              }}
            >
              <TextHeading>When can I get a refund?</TextHeading>
              <TriText>
                The refund wallet will be active for 48 hours. Between Block #
                to Block#
              </TriText>
            </div>
            <div
              style={{
                maxWidth: "400px",
                color: "white",
              }}
            >
              <TextHeading>How do I get the refund?</TextHeading>
              <TriText>
                Connect your wallet to the Refund contract. See if you are
                entitled to a refund and how many Arties you can burn Burn the
                Artie/s. Wait for the transaction to settle
              </TriText>
            </div>
          </ColumnFlex>
        </RowFlex>
      </div>
      <div
        style={{
          backgroundColor: APP_COLORS.yellow,
          height: "4vh",
          width: "100%",
        }}
      />
    </ColumnFlex>
  );
};

export default ArtLand;
