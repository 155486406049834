import { createGlobalStyle, ThemeProvider } from "styled-components";
import { normalize } from "styled-normalize";

import "../css/App.css";

import { APP_THEME } from "../const/theme";

import StateLogic from "./state";

import ConnectWallet from "./ConnectWallet";
import ComingSoon from "./ComingSoon";

const BG_COLOR = "#FFFFFF";
export const GlobalStyle = createGlobalStyle`
  ${normalize}
  html {
    height: 100%;
  }
  body {
    display: block;
    height: 100%;
    background-color: ${BG_COLOR};


    margin: 0;
    min-height: 100vh;
   
   
    font-family: Arial,sans-serif;
   
  }
  #root {
    display: block;
    height: 100%;
    background-color: ${BG_COLOR};


    margin: 0;
    min-height: 100%;
   
    font-family: Arial,sans-serif;
  }
`;

function App() {
  return (
    // <ComingSoon />
    <ThemeProvider theme={APP_THEME}>
      <StateLogic>
        <GlobalStyle />
        <ConnectWallet />
      </StateLogic>
    </ThemeProvider>
  );
}

export default App;
