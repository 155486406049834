import React, { createContext, useContext, useEffect, useState } from "react";

export enum ARTIES_STATUS {
  REFUNDED,
  PROCESSING,
  NOTHING,
  FAILED,
}
export type ARTIES_TYPE = {
  id: number;
  image: string;
  status: ARTIES_STATUS;
  txId?: string;
  failedReason?: string;
};

type INIT_STATE_TYPE = {
  senderAddress?: string;
  authenticated: boolean;

  arties: ARTIES_TYPE[];
  latestBlock: number | null;

  _senderAddress: (address: string | undefined) => void;
  _authenticated: (authenticated: boolean) => void;

  _arties: (arties: ARTIES_TYPE[]) => void;

  _latestBlock: (status: number | null) => void;
};

const INIT_STATE = {
  senderAddress: undefined,
  authenticated: false,
  _arties: [],
};

export const StateContext = createContext<INIT_STATE_TYPE>(null!);

const StateLogic = (props: React.PropsWithChildren<{}>) => {
  const [senderAddress, _senderAddress] = useState<string | undefined>(
    INIT_STATE.senderAddress
  );
  const [authenticated, _authenticated] = useState<boolean>(false);

  const [arties, _arties] = useState<ARTIES_TYPE[]>([]);

  const [latestBlock, _latestBlock] = useState<number | null>(null);

  useEffect(() => {}, [arties]);
  let contextValue = {
    senderAddress,
    _senderAddress,
    authenticated,
    _authenticated,

    arties,
    _arties,
    latestBlock,
    _latestBlock,
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
};

export default StateLogic;

export const useAppState = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
};
