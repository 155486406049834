import { AppConfig, UserSession } from "@stacks/connect";
import { useConnect, Connect } from "@stacks/connect-react";
import {
  AccountsApi,
  AddressNftListResponse,
  Configuration,
  Middleware,
  TransactionsApi,
} from "@stacks/blockchain-api-client";

import { ARTIES_STATUS, ARTIES_TYPE, useAppState } from "./state";

import { ColumnFlex } from "./styled";

import { NavigationHeader } from "./Landing";
import Burn from "./Burn";
import { useEffect, useState } from "react";
import ArtLand from "./ArtLand";
import {
  accountsApi,
  ARTIES_BURN_CONTRACT,
  ARTIES_MINT_CONTRACT,
  STACKS_USER_SESSION,
} from "../utils";
import BlockSub from "./BlockSub";

type BasicNFTHoldingResType = {
  limit: number;
  offset: number;
  total: number;
  results: any[];
};

const ConnectWallet = () => {
  const { authenticated, _authenticated, _senderAddress, arties, _arties } =
    useAppState();
  const [loading, _Loading] = useState<boolean>(false);

  useEffect(() => {
    const senderAddy = localStorage.getItem("walletID");
    const arites = localStorage.getItem("arties");

    if (senderAddy) {
      //fetchArties(senderAddy);
      _authenticated(true);
      _senderAddress(senderAddy);
      fetchMonkeyEggs(senderAddy);
    }

    if (arites) {
      _Loading(false);
      const localArties: ARTIES_TYPE[] = JSON.parse(arites) as ARTIES_TYPE[];
      _arties(localArties);
    }
  }, []);

  const fetchMonkeyEggs = async (senderAddress: string) => {
    //const senderAddress = "SP1CE3NQXDKCJ2KEFFGCVFA5C196S9F0RRX93HY87";
    try {
      _Loading(true);

      const STACKS_API = "https://stacks-node-api.mainnet.stacks.co/";
      const CONTRACT =
        "SP2BE8TZATXEVPGZ8HAFZYE5GKZ02X0YDKAN7ZTGW.arties::arties";

      //Fetch all NFT related to this wallet and monkey contract
      const _nftHoldingsAPIRes = await fetch(
        `${STACKS_API}extended/v1/tokens/nft/holdings?principal=${senderAddress}&asset_identifiers=${CONTRACT}`
      )
        .then((res) => res.text())
        .then((text) => text);

      if (_nftHoldingsAPIRes) {
        //Check if _nftHoldings is not empty, create func to fetch more NFT if wallet has more than 50
        const nftHoldings = JSON.parse(
          _nftHoldingsAPIRes
        ) as BasicNFTHoldingResType;

        const totalNFT = nftHoldings.total;

        let artiesInWallet: any[] = nftHoldings.results;
        let totalFetchedNFT = nftHoldings.results.length;

        if (nftHoldings.total >= 50) {
          while (totalFetchedNFT < totalNFT) {
            const loopFetchData = await fetch(
              `${STACKS_API}extended/v1/tokens/nft/holdings?principal=${senderAddress}&asset_identifiers=${CONTRACT}&offset=${totalFetchedNFT}`
            )
              .then((res) => res.text())
              .then((text) => text);

            const _lpd = JSON.parse(loopFetchData);

            if (_lpd.results) {
              artiesInWallet = [...artiesInWallet, ..._lpd.results];
              totalFetchedNFT = totalFetchedNFT + _lpd.results.lenggh;
            }
          }
        }

        const eggs: ARTIES_TYPE[] = [];

        for (const artie of artiesInWallet) {
          const workAround = artie as any;
          const _artiesId = workAround.value.repr.replace(/\D/g, "");
          const artiesId: number = +_artiesId;

          //https://gamma.io/api/v1/collections/SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.deruptars/1

          const gammaRes = await fetch(
            `https://gamma.io/api/v1/collections/SP2BE8TZATXEVPGZ8HAFZYE5GKZ02X0YDKAN7ZTGW.arties/${artiesId}`
          )
            .then((res) => res.text())
            .then((text) => text);

          const yourStillGoing: any = JSON.parse(gammaRes);

          console.log("gammaRes", yourStillGoing);

          let imageurl = "";
          if (yourStillGoing.result) {
            const imageHash: string =
              yourStillGoing.data.token_metadata.image_url;
            imageurl = `https://arweave.net/${imageHash.substring(5)}`;
          }
          const types: ARTIES_TYPE = {
            id: artiesId,
            image: imageurl,

            status: ARTIES_STATUS.NOTHING,
          };

          eggs.push(types);
        }

        localStorage.setItem("arties", JSON.stringify(eggs));

        const localStorageArites = localStorage.getItem("arties");
        if (localStorageArites) {
          const temp: ARTIES_TYPE[] = JSON.parse(localStorageArites);

          for (const localArites of temp) {
            const isAlreadyFetched = eggs.findIndex((d, i) => {
              return d.id === localArites.id;
            });

            if (isAlreadyFetched > -1) {
              eggs[isAlreadyFetched] = localArites;
            }
          }
        }

        _arties(eggs);

        _Loading(false);
      }
    } catch (err) {
      console.log("noooo", err);
      _Loading(false);
    }
  };

  if (loading && arties.length > 0) {
    return (
      <ColumnFlex height="70%" alignItems="center" justifyContent="center">
        loading
      </ColumnFlex>
    );
  }

  const AUTH_OPTIONS: any = {
    appDetails: {
      name: "Arties",
      icon: "https://pbs.twimg.com/profile_images/1449482051711692805/9bK00Rzc_400x400.jpg",
    },
    username: "test",
    redirectTo: "/",
    onFinish: async () => {
      try {
        _Loading(true);
        const userData = STACKS_USER_SESSION.loadUserData();
        const senderAddress = userData.profile.stxAddress.mainnet;

        _authenticated(true);
        _senderAddress(senderAddress);

        fetchMonkeyEggs(senderAddress);
        localStorage.setItem("walletID", senderAddress);
      } catch (err) {
        console.log("noooo", err);
        _Loading(false);
      }
    },
    userSession: STACKS_USER_SESSION,
  };

  return (
    <Connect authOptions={AUTH_OPTIONS}>
      <>
        <ArtLand />
      </>
    </Connect>
  );
};
export default ConnectWallet;
